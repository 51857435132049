















import {Vue, Component} from "vue-property-decorator";
import BillDetailHeadType from "./indexType";

@Component({name: "BillDetailHead"})
export default class BillDetailHead extends Vue implements BillDetailHeadType{
}
