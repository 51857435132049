














import {Vue, Component} from "vue-property-decorator";
import BillDetailListType from "./indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import BillDetailItem from "@/views/Money/BillDetail/components/Item/index.vue";

@Component({name: "BillDetailList",components:{ PullDownUpList,ListBottom,BillDetailItem }})
export default class BillDetailList extends Vue implements BillDetailListType{
    PullDown = false
    StopUp = false
    UpDownBool = false

    handlePullDown(): void {
        //
    }

    handleUpDown(): void {
        //
    }

    handleUpData(bool: boolean): void {
        //
    }
}
