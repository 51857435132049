















import {Vue, Component} from "vue-property-decorator";
import BillDetailType from "./BillDetail";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import BillDetailHead from "@/views/Money/BillDetail/components/Head/index.vue";
import MoneyTitle from "@/views/Money/components/Title/index.vue";
import BillDetailList from "@/views/Money/BillDetail/components/List/index.vue";
import ZoomPage from "../../../impView/PageSafety";
import { titleList } from './Model/index';

@Component({name: "BillDetail",components:{ HeadTop,BillDetailList,BillDetailHead,MoneyTitle }})
export default class BillDetail extends ZoomPage implements BillDetailType{
    titleList = titleList

    mounted(){
        this.loadingShow = false
    }
}
