







import {Vue, Component, Prop} from "vue-property-decorator";
import MoneyTitleType from "./indexType";

@Component({name: "MoneyTitle"})
export default class MoneyTitle extends Vue implements MoneyTitleType{

    @Prop(Array)
    List!:string[]
}
