




















import {Vue, Component} from "vue-property-decorator";
import BillDetailItemType from "./indexType";

@Component({name: "BillDetailItem"})
export default class BillDetailItem extends Vue implements BillDetailItemType{
}
